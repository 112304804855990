//TODO - ADD/REMOVE and rename action types as per requirements for maritime map

export const GET_MARINE_MAP = 'GET_MARINE_MAP';
export const GET_MARINE_MAP_LAYOUT = 'GET_MARINE_MAP_LAYOUT';
export const GET_MARINE_MAP_START = 'GET_MARINE_MAP_START';
export const GET_MARINE_MAP_SUCCESS = 'GET_MARINE_MAP_SUCCESS';
export const GET_MARINE_MAP_FAIL = 'GET_MARINE_MAP_FAIL';
export const UPDATE_SELECTED_CATEGORIES = 'UPDATE_SELECTED_CATEGORIES';
export const UPDATE_GEOJSON_DATA = 'UPDATE_GEOJSON_DATA';
export const UPDATE_PLACES = 'UPDATE_PLACES';
export const SET_MAP_STEP = 'SET_MAP_STEP';
export const SET_RESELECTED_STATUS = 'SET_RESELECTED_STATUS';
export const SHOULD_MAP_ZOOM = 'SHOULD_MAP_ZOOM';
export const SET_MAP_PANNING = 'SET_MAP_PANNING';
export const SET_CLEARING_ALL = 'SET_CLEARING_ALL';
