import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from './styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  vadMain: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.palette.common.White,
    marginTop: '-1px',
    '&.MapAdjuster': {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
    }
  },
  '@global': {
    '.comp-2:not(.comp-0 + .comp-1 + .comp-2), .comp-3:not(.comp-0 + .comp-1 + .comp-2 + .comp-3), .comp-4:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4), .comp-5:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5), .comp-6:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6), .comp-7:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7), .comp-8:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8), .comp-9:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9), .comp-10:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10), .comp-11:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10 + .comp-11), .comp-12:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10 + .comp-11 + .comp-12), .comp-13:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10 + .comp-11 + .comp-12 + .comp-13), .comp-14:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10 + .comp-11 + .comp-12 + .comp-13 + .comp-14)': {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
      visibiiity: 'hidden',
    },
  },
  banner: {
    // position: 'relative',
    // // zIndex: 1301,
    // paddingTop: '1px',
    // marginTop: '-2px',
    // backgroundColor: theme.palette.common.White,
    // paddingBottom: '1px',
  },
  toastMessage: {
    '&.smallToast': {
      '& .toast-container': {
        maxWidth: '100%',
      },
    },
    '& .toast-container': {
      maxWidth: pxToRem(608),
      minWidth: pxToRem(448),
      width: 'fit-content',
      padding: '0',
      bottom: pxToRem(0),
      // left: pxToRem(72),
      ...theme.mixins.left(pxToRem(72)),
      [theme.breakpoints.down('md')]: {
        minWidth: pxToRem(350),
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: '100%',
        bottom: pxToRem(0),
        ...theme.mixins.left(pxToRem(0)),
      },
      // [theme.breakpoints.down('sm')]: {
      //   maxWidth: '351px',
      //   bottom: pxToRem(0),
      //   // left: pxToRem(12),
      //   ...theme.mixins.left(pxToRem(12)),
      // },
      '& .Toastify__toast': {
        background: '#ffffff',
        width: 'fit-content',
        width: '100%',
        // padding: '26px 16px 24px',
        padding: '16px 16px 16px',
        borderRadius: '4px',
        margin: '0',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
          padding: '16px 24px',
          // marginLeft: '12px',
          maxWidth: '100%',
          width: '100%',
          height: '100%',
          minHeight: '24px',
          marginBottom: '0',
          borderRadius: '0px',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        },
        // [theme.breakpoints.down('sm')]: {
        //   padding: '18px 16px 16px',
        //   // marginLeft: '12ppx',

        //   maxWidth: '351px',
        //   height: '100%',
        //   minHeight: '24px',
        //   marginBottom: '12px',
        // },
        '& .Toastify__toast-body': {
          margin: 0,
          padding: 0,
        },
        '& .Toastify__close-button': {
          display: 'none',
        },
        '& .Toastify__progress-bar': {
          background: '#FF8707',
          bottom: '0',
          height: '3px',
          borderTopLeftRadius: '2px',
          borderTopRightRadius: '2px',
        },
        '& .contentWrapper': {
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
          },
          '& .innerWrapper': {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              width: '100%',
              '& .MuiTypography-body2': {
                width: '100%',
              },
            },
            [theme.breakpoints.down('sm')]: {
              justifyContent: 'space-between',
              flexDirection: 'row-reverse'
            }
          },
          '& .MuiTypography-root': {
            color: '#000',
            ...theme.mixins.marginLeft(16),
            [theme.breakpoints.down('sm')]: {
              fontSize: pxToRem(16),
              lineHeight: pxToRem(24),
              ...theme.mixins.marginLeft(0),
            },
            '&:first-child': {
              ...theme.mixins.paddingRight(15),
              [theme.breakpoints.down('md')]: {
                fontSize: '16px'
              }
            },
          },
          '& .link': {
            display: 'flex',
            alignItems: 'center',
            ...theme.mixins.marginLeft('auto'),
            [theme.breakpoints.down('sm')]: {
              marginTop: pxToRem(12),
              width: '100%',
              justifyContent: 'center'
            },
            // '[dir="rtl"] &': {
            //   flexDirection: 'row-reverse',
            //   justifyContent: 'center'
            // },
            '& .MuiTypography-root ': {
              ...theme.mixins.marginLeft(0)
            },
            '& svg': {
              // marginRight: '12px',
              ...theme.mixins.marginRight(pxToRem(12)),
              '[dir="rtl"] &': {
                transform: 'rotate(180Deg)',
                // ...theme.mixins.marginRight(pxToRem(0)),
                // ...theme.mixins.marginLeft(pxToRem(12)),
              },
              [theme.breakpoints.down('sm')]: {
                // marginRight: '8.87px',
                ...theme.mixins.marginRight(pxToRem(8.87)),
              },
            },
          },
        },
      },
    },
  },
}));
export default useStyles;