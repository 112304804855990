import * as actionTypes from './actionTypes';

const createAction = type => payload => ({ type, payload });

//TODO - ADD/REMOVE actions as per requirements for maritime map
export const getMarineMap = createAction(actionTypes.GET_MARINE_MAP);
export const getMarineMapStart = createAction(
  actionTypes.GET_MARINE_MAP_START
);
export const getMarineMapSuccess = createAction(
  actionTypes.GET_MARINE_MAP_SUCCESS
);
export const getMarineMapFail = createAction(actionTypes.GET_MARINE_MAP_FAIL);
export const updateSelectedCategories = createAction(
  actionTypes.UPDATE_SELECTED_CATEGORIES
);
export const updateGeoJSONData = createAction(actionTypes.UPDATE_GEOJSON_DATA);
export const updatePlacesInView = createAction(actionTypes.UPDATE_PLACES);
export const setCellReselectedStatus = createAction(
  actionTypes.SET_RESELECTED_STATUS
);
export const setMapStepValue = createAction(actionTypes.SET_MAP_STEP);
export const handleShouldMapZoom = createAction(actionTypes.SHOULD_MAP_ZOOM);
export const setIsMapPanning = createAction(actionTypes.SET_MAP_PANNING);
export const setClearingAll = createAction(actionTypes.SET_CLEARING_ALL);
