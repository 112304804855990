//TODO - ADD/REMOVE and rename saga functions as per requirements for maritime map

import { all, put, takeLatest, select } from 'redux-saga/effects';

import * as actions from './actions';
import * as actionTypes from './actionTypes';
import fetchService from '../../../../../services/FetchService';

/*
 * Get ExploreMap Saga
 */
export function* getMarineMapSaga(action) {
  yield put(actions.getMarineMapStart());
  try {
    const response = yield fetchService({
      url: action.payload.apiUrl,
      method: 'POST',
      data: action.payload.payload,
    });
    if (response.status === 200) {
      yield put(actions.getMarineMapSuccess(response.data));
    } else {
      yield put(actions.getMarineMapFail(response.data));
    }
  } catch (error) {
    console.log('Error at Catch getMarineMapSaga', error);
    yield put(
      actions.getMarineMapFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* watchMarineMap() {
  yield all([takeLatest(actionTypes.GET_MARINE_MAP, getMarineMapSaga)]);
}
