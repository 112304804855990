import { MARKER_TYPES } from '../Constants';
import * as actionTypes from './actionTypes';
import get from 'lodash/get';

const initialState = {
  loading: true,
  error: null,
  message: '',
  geoJsonData: { features: [], crs: null, activities: [] },
  destinations: [],
  features: [],
  places: [],
  completePlaces: [],
  selectedCategories: [],
  selectedCellID: null,
  isCellReselected: false,
  mapStep: 0,
  placesInView: [],
  isMapPanning: false,
  shouldMapZoom: false,
  clearAllClick: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MARINE_MAP_START:
      return { ...state, error: null, loading: true };

    case actionTypes.GET_MARINE_MAP_SUCCESS:
      const features = (get(action, 'payload.features', []) || []).map((feature) => ({ ...feature, properties: { ...(get(feature, 'properties', {}) || {}), type: MARKER_TYPES.LOCATION } }));
      const activities = (get(action, 'payload.activities', []) || []).map((feature) => ({ ...feature, properties: { ...(get(feature, 'properties', {}) || {}), type: MARKER_TYPES.ACTIVITY } }));
      const transformedGeoJsonData = {
        ...action.payload,
        features,
        activities
      }
      return {
        ...state,
        geoJsonData: transformedGeoJsonData,
        loading: false,
        error: null,
      };

    case actionTypes.GET_MARINE_MAP_FAIL:
      return { ...state, error: true, loading: false, message: action.message, geoJsonData:  { features: [], crs: null, activities: [] } };

    case actionTypes.SET_MAP_STEP:
      return { ...state, mapStep: action.payload };

    case actionTypes.SHOULD_MAP_ZOOM:
      return { ...state, shouldMapZoom: action.payload };

    case actionTypes.SET_MAP_PANNING:
      return { ...state, isMapPanning: action.payload };

    case actionTypes.SET_CLEARING_ALL:
      return { ...state, clearAllClick: action.payload };

    default:
      return state;
  }
};
