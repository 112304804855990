export const TAB_CONTEXT = {
  LOCATION: 'LOCATION',
  ACTIVITY: 'ACTIVITY',
};

export const MARKER_TYPES = {
  LOCATION: 'location',
  ACTIVITY: 'activity',
  FACILITY: 'facility',
}

export const ISLAND_TYPES = {
  PRIVATE: 'private'
}
