import * as actionTypes from './actionTypes';

/**
 * actions for fetching filters data
 */
export const getFiltersInfoData = payload => {
  return {
    type: actionTypes.GET_FILTERSINFO_DATA,
    payload,
  };
};

export const getFiltersInfoStart = () => {
  return {
    type: actionTypes.GET_FILTERSINFO_START,
  };
};

export const getFiltersInfoSuccess = payload => {
  return {
    type: actionTypes.GET_FILTERSINFO_SUCCESS,
    payload,
  };
};

export const getFiltersInfoFail = payload => {
  return {
    type: actionTypes.GET_FILTERSINFO_FAIL,
    payload,
  };
};

/**
 * Actions fot updating filters data
 */
export const updateFiltersData = data => {
  return {
    type: actionTypes.UPDATE_FILTERSINFO_DATA,
    data,
  };
};

export const updateMultipleFiltersData = data => {
  return {
    type: actionTypes.UPDATE_MULTIPLE_FILTERSINFO_DATA,
    data,
  };
};

export const updateSelectedFiltersData = data => {
  return {
    type: actionTypes.UPDATE_SELECTED_FILTER_DATA,
    data,
  };
};

export const mobileFiltersUpdate = data => {
  return {
    type: actionTypes.UPDATE_SELECTED_FILTER_DATA_MOBILE,
    data,
  };
};

export const updateQueryFilterData = data => {
  return {
    type: actionTypes.UPDATE_QUERY_FILTERSINFO_DATA,
    data,
  };
};

export const clearFilterData = (data) => {
  return {
    type: actionTypes.CLEAR_SELECTED_FILTER_DATA,
    data,
  };
};

/**
 * Action to get filtered data
 */
export const getFilterdInfoData = payload => {
  return {
    type: actionTypes.GET_FILTERD_DATA,
    payload,
  };
};

export const removeFilterdListById = payload => {
  return {
    type: actionTypes.REMOVE_FILTERED_DATA_BY_ID,
    payload,
  };
};

export const getFilterdInfoStart = () => {
  return {
    type: actionTypes.GET_FILTERD_DATA_START,
  };
};

export const getFilterdInfoSuccess = payload => {
  return {
    type: actionTypes.GET_FILTERD_DATA_SUCCESS,
    payload,
  };
};

export const getFilterdInfoFail = payload => {
  return {
    type: actionTypes.GET_FILTERD_DATA_FAIL,
    payload,
  };
};

export const clearFilteredData = () => {
  return {
    type: actionTypes.CLEAR_FILTERD_DATA,
  };
};

export const clearAllClicked = payload => {
  return {
    type: actionTypes.CLEAR_ALL_CLICKED,
    payload,
  };
};

export const getFavoritesInNavData = payload => {
  return {
    type: actionTypes.GET_FAVORITES_IN_NAV_DATA,
    payload,
  };
};

export const getFavoritesInNavStart = () => {
  return {
    type: actionTypes.GET_FAVORITES_IN_NAV_DATA_START,
  };
};

export const getFavoritesInNavSuccess = payload => {
  return {
    type: actionTypes.GET_FAVORITES_IN_NAV_DATA_SUCCESS,
    payload,
  };
};

export const getFavoritesInNavFail = payload => {
  return {
    type: actionTypes.GET_FAVORITES_IN_NAV_DATA_FAIL,
    payload,
  };
};

export const clearFavoritesInNavData = () => {
  return {
    type: actionTypes.CLEAR_FAVORITES_IN_NAV_DATA,
  };
};
export const addItinerary = (payload) => {
  return {
    type: actionTypes.ADD_ITINERARY,
    payload,
  };
};
export const setViewAllFavLink = (payload) => {
  return {
    type: actionTypes.SET_VIEW_ALL_FAV_LINK,
    payload,
  };
};
export const setPreviewItineraryLink = (payload) => {
  return {
    type: actionTypes.SET_PREVIEW_ITINERARY_LINK,
    payload,
  };
};

export const updateFiltersForAdCalendarData = data => {
  return {
    type: actionTypes.UPDATE_FILTERS_FOR_AD_CALENDAR_DATA,
    data,
  };
};

export const shouldResetFiltersForAdCalendarData = data => {
  return {
    type: actionTypes.SHOULD_RESET_FILTERS_FOR_AD_CALENDAR,
    data,
  };
};
